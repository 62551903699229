import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
// @ts-ignore
import * as styles from "../components/trustee-areas.module.css"

const officialSite =
  "https://sccs.net/community/planning_trustee_area_elections"

const officialMap =
  "https://ndcresearch.maps.arcgis.com/apps/View/index.html?" +
  [
    "appid=b634cdbf3cc94baa8536deb279f0833d",
    "extent=-122.0926,36.9259,-121.9827,36.9929",
    "show-panel=false",
    "disable-scroll=true",
  ].join("&")

function TrusteeAreasPage() {
  return (
    <Layout>
      <Seo
        title="Trustee Areas for Santa Cruz City Schools"
        description="The map for 2022 Trustee Area Elections of Santa Cruz City Schools is finalized and ready for you to review."
        card="sccs-trustee-areas.png"
      />
      <div>
        <h1>Trustee Area 2</h1>
        <p>
          Santa Cruz City Schools moved to{" "}
          <a href={officialSite}>Trustee Area elections</a> in 2020. The most
          recent{" "}
          <a href={officialMap}>map for the 2022 election has been finalized</a>
          . Find your district in this map.
        </p>
        <iframe className={styles.trusteeAreaMap} src={officialMap} />
      </div>
    </Layout>
  )
}

export default TrusteeAreasPage
